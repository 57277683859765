import React from "react"
import { graphql, StaticQuery } from "gatsby"
import PubblicazioniTemplate from "./pubblicazioniTemplate";

var autori = []

export default (props) => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "pubblicazioni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        id
                        frontmatter {
                            date(formatString: "DD/MM/YYYY")
                            title
                            author
                            file_pdf
                        }
                    }
                }
            }
        }
    `}
        render={data => (
            data.allMarkdownRemark.edges.map(item => {
                if (props.all == '0') {
                    if (autori.indexOf(item.node.frontmatter.author) === -1) {
                        autori.push(item.node.frontmatter.author)
                        return <div key={item.node.id} id={'tabs-' + item.node.id}><PubblicazioniTemplate key={item.node.frontmatter.date + item.node.frontmatter.author} author={item.node.frontmatter.author} /></div>
                    } else {
                        return null
                    }
                } else {
                    return <div key={item.node.id} id={'tabs-tutte'}><PubblicazioniTemplate key={item.node.frontmatter.date + item.node.frontmatter.author} author='all' /></div>
                }
            }
            )
        )}
    />
)
