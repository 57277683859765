import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import '../css/pages/pubblicazioni.css'
import PubblicazioniLeftTemplate from '../templates/pubblicazioni/pubblicazioniLeftTemplate'
import PubblicazioniContainerTemplate from '../templates/pubblicazioni/pubblicazioniContainerTemplate';

import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'

const PubblicazioniPage = ({ pathContext: { locale } }) => (
	<>
		<Helmet
			bodyAttributes={{
				className: 'transition-support webkit pubblicazioni'
			}}
		>
		</Helmet>
		<Layout locale={locale}>
			<div className="pubblicazioni ">
				<div className="content clearfix">

					<div className="section-block intro-title-2 header-image redFilter"></div>

					<div className="underHeader">
						<div className="item">
							<h1><FormattedMessage id="pubbMainTitle" /></h1>
						</div>
					</div>

					<div className="tabs style-2">
						<ul className="tab-nav cont-left">
							<li className={'active'}>
								<a href="#tabs-tutte">Tutte</a>
							</li>
							<PubblicazioniLeftTemplate></PubblicazioniLeftTemplate>
						</ul>
						<div className="tab-panes cont-right">
							<PubblicazioniContainerTemplate all="1"></PubblicazioniContainerTemplate>
							<PubblicazioniContainerTemplate all="0"></PubblicazioniContainerTemplate>
						</div>
					</div>
				</div>
			</div>
		</Layout >
	</>
)

export default PubblicazioniPage
