import React from "react"
import { graphql, StaticQuery } from "gatsby"

var autori = []
var n = 0

export default () => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "pubblicazioni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        id
                        frontmatter {
                            author
                        }
                    }
                }
            }
        }
    `}
        render={data => (
            data.allMarkdownRemark.edges.map(item => {
                if (autori.indexOf(item.node.frontmatter.author) == -1) {
                    autori.push(item.node.frontmatter.author)
                    n++
                    return <li key={item.node.id} ><a href={"#tabs-" + item.node.id}>{item.node.frontmatter.author}</a></li>
                } else {
                    return null
                }
            }
            )
        )}
    />
)
