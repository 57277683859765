import React, { Fragment } from "react"
import { graphql, StaticQuery } from "gatsby"

export default (props) => (
    <StaticQuery
        query={graphql`
        {
            allMarkdownRemark(filter: {collection: {eq: "pubblicazioni"}}, sort: {order: DESC, fields: [frontmatter___date]}) {
                edges {
                    node {
                        frontmatter {
                            date(formatString: "YYYY")
                            title
                            author
                            file_pdf
                        }
                        html
                    }
                }
            }
        }
    `}

        render={data => {
            return data.allMarkdownRemark.edges.map(item =>
                props.author != 'all' ?
                    props.author == item.node.frontmatter.author
                        ?
                        <div class="tab-text" style={{ marginBottom: '20px' }}>
                            <h3>{item.node.frontmatter.title}</h3>
                            <p>
                                <b>Anno:</b> {item.node.frontmatter.date}<br /><b>Pubblicazione:</b>
                                {item.node.html ?
                                    <div dangerouslySetInnerHTML={{ __html: item.node.html }}></div>
                                    :
                                    null
                                }
                            </p>
                        </div>
                        :
                        null
                    :
                    <div class="tab-text" style={{ marginBottom: '20px' }}>
                        <h3>{item.node.frontmatter.title}</h3>
                        <p>
                            <b>Anno:</b> {item.node.frontmatter.date}<br /><b>Autore:</b> {item.node.frontmatter.author}<br /><b>Pubblicazione:</b>
                            {item.node.html ?
                                <div dangerouslySetInnerHTML={{ __html: item.node.html }}></div>
                                :
                                null
                            }
                        </p>
                    </div>
            )
        }
        }
    />
)
